import React, { Component } from 'react'; //different
//import './style.css';
import instance from "./axios-instance";
import SweetAlert from 'react-bootstrap-sweetalert';
import jwt_decode from "jwt-decode";
import NavMenu from './navmenu';
import Systems from "./SystemFunction";
import Banner from './banner';
import Swal from 'sweetalert2'
import md5 from 'md5';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            playerid: null,
            user_id: null,
            point: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
        }
        this.handleClick = this.handleClick.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.hideAlert = this.hideAlert.bind(this);

    }
    handlePageChange(pageNumber) {
        //////console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });

        instance
            .get("/api/v1/rewardlist", {
                params: {
                    Cat_ID: 0,
                    page: pageNumber
                }
            })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
    }

    handleClick = async (e) => {
        this.setState({
            activePage: 1,
            isActive: true,
            activeTab: 0
        });
        instance.get("/api/v1/rewardlist", {
            params: {
                Cat_ID: e.target.id,
                page: 1
            }
        })
            .then((res) => {
                this.setState({
                    rewardlist: res.data.message.products,
                    pagecount: res.data.message.pagecount,
                    products_page_count: res.data.message.products_page_count
                });
            });
        this.setState({
            isActive: false
        });
    }

    hideAlert() {
        this.setState({
            alert: null
        });
    }


    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            this.setState({
                user_id: decoded.message.playerid,
                playerid: decoded.message.member_id,
                firstname: decoded.message.firstname,
                lastname: decoded.message.lastname,
                point: decoded.message.Point,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }
    notify = async (mode, msg) => {
        console.log(mode, msg);
        if (mode == "success") {
            await toast.success(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "warning") {
            await toast.warning(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "error") {
            await toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        else if (mode == "info") {
            await toast.info(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        await this.waitnotify();
    }
    waitnotify = async () => {
        setTimeout(() => { console.log('World!') }, 5000)
    }
    async opencliamcashout(evt, cityName) {

        var token = localStorage.getItem('auth_token');

        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            await instance.post("/api/v1/cliamcashout", {
                userid: Username,
                System: Systems,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                if (res.data.status === 200) {
                    var decoded = jwt_decode(token);
                    var Username = decoded.message.playerid;
                    this.rechecklogin(Username, token);
                    await this.notify("success", 'ทำรายการรับเงินเรียบร้อย');
                }
                else if (res.data.status === 201) {
                    await this.notify("error", 'ไม่สามารถทำรายการได้ ไม่มียอดเงิน');
                    return false;
                }
                else if (res.data.status === 202) {
                    await this.notify("error", 'ไม่สามารถทำรายการได้');
                    return false;
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        } else {
            await this.notify("error", 'คุณยังไม่ได้ทำการล๊อคอิน');
        }
    }
    rechecklogin = async (Username, token) => {
        try {

            //const { webnamex } = this.state
            //  console.log(1406, webnamex);
            //console.log(3);
            const search = window.location.href;
            const paramx = new URLSearchParams(search);
            // const space = search.split("/")
            const space = window.location.host
            var userhash = md5(Username);
            //  const linkaff = "https://pgline88.com/?register=" + userhash;
            const linkaff = "https://" + space + "/register?reg=" + userhash;
            await instance.post("/api/v1/checkpoint", {
                userid: Username,
                System: Systems,
                Update: userhash,
                Function: 'rechecklogin-App.js'
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(async (res) => {
                // //console.log(res.data);
                if (res.data.status === 200) {
                    this.calllisthistory(Username);
                    //console.log(443, res.data);
                    localStorage.setItem('auth_token', res.data.token)
                    var rankall = res.data.tblrankall;
                    const found = rankall.find(obj => {
                        return obj.checked === 1;
                    });
                    this.setState({
                        tblrankall: res.data.tblrankall,
                        linkaff: linkaff,
                        buttoncliam: (found === undefined) ? true : false,
                        tblnextrank: res.data.tblnextrank,
                        rankpic: (res.data.tblrank.image == null || res.data.tblrank.image == "") ? "https://betner.co/build/rank/1.jpeg" : (res.data.tblrank.picrack == null || res.data.tblrank.picrack == "") ? "https://betner.co/build/rank/" + res.data.tblrank.image : res.data.tblrank.picrack,
                        rankname: res.data.tblrank.name,
                        rankob: res.data.tblrank,
                        tblprofile: res.data.message,
                        rankpoint: res.data.rankpoint,
                        accnumber: res.data.message.accnumber,
                        userid: res.data.message.member_id,
                        username: res.data.message.playerid,
                        fullname: res.data.message.firstname,
                        point: res.data.message.Point,
                        balance: res.data.databalance,
                        loginstate: true,
                    });
                }
                else if (res.data.status === 401) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }

            }).catch(async (err) => {
                // console.log(err);
                if (err.response.status === 401 || err.response.status === 403) {
                    localStorage.clear();
                    await Swal.fire({
                        title: 'Warning!',
                        text: 'Session ของท่านหมดอายุ กรุณา login เข้าสู่ระบบอีกครั้ง',
                        icon: 'warning',
                        confirmButtonText: 'ตกลง'
                    })
                    window.location.href = '/';
                }
            });
        }
        catch (ex) {
            ////////console.log(ex);
        }

    }

    async componentDidMount() {
        var token = localStorage.getItem('auth_token');
        const search = window.location.href;
        const params = new URLSearchParams(search);
        //////console.log(params);

        if (token != null) {
            var decoded = jwt_decode(token);
            var Username = decoded.message.playerid;
            this.rechecklogin(Username, token);

        }
        await instance.post("/api/v1/mainsetting", {
            System: Systems,
        }).then((res) => {
            if (res.data.status === 200) {
                const datas = res.data.message;
                var min = res.data.tblcatagoryx[0].limitdeposit;
                var max = res.data.tblcatagoryx[0].maxdeposit;
                var maxwithdrawstatus = res.data.tblcatagoryx[0].withdrawmax
                var limit = { min, max }
                var caltype = 0
                if (res.data.tblinvitedsetting.length > 0) caltype = res.data.tblinvitedsetting[0].caltype
                this.setState({
                    maxwithdrawstatus: maxwithdrawstatus,
                    linelink: datas[1].value,
                    webnamex: datas[7].value,
                    System: res.data.tblcatagoryx[0].System,
                    cashoutSet: res.data.tblcashoutsetting[0],
                    inviteSet: res.data.tblinvitedsetting[0],
                    typeinvite: caltype,
                    datas: datas,
                    limit: limit,
                });
            }
            else {
                // this.CheckThisGoalError();
            }
        });

    }

    render() {
        const { cashoutSet, typeinvite, inviteSet, loginstate, trueaccount, webnamex, stateopterror, changepassword, username, balance, accnumber, fullname, mainacc, limit, rankob, rankpoint, tblnextrank, userrecomend, linkaff, tblprofile, proderedeem, proderedeemlist, prodetail } = this.state

        return (
            <>

                <div className="container p-0 x-margin-top-v3">
                    <div className="row m-0 border-shadow-title">
                        <div className="col-12 p-0 col-sm-7 col-md-7 col-lg-7 col-xl-7 mx-auto my-auto text-white-v1">
                            <div className="text-center bg-title-web">
                                <div>
                                    <div className="text-title text-white-v1 text-center">
                                        <div className="d-flex justify-content-between">
                                            <div className="text-white-v1-deposit">
                                                <a role="button" href='/'>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={25}
                                                        height={25}
                                                        fill="currentColor"
                                                        className="bi bi-arrow-left-circle text-color-back"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"
                                                        />
                                                    </svg>
                                                </a>
                                            </div>
                                            <div className="text-white-v1-deposit">
                                                <h5>คืนยอดเสีย</h5>
                                            </div>
                                            <div>{/**/}</div>
                                        </div>
                                        <hr className="mt-0" />
                                    </div>
                                </div>
                            </div>
                            <div className="p-2 bg-profile-box-overview">
                                {/**/}
                                <div className="mt-1 mb-1 w-100">
                                    <div className="img">
                                        <div
                                            className="px-0 animated animatedFadeInUp fadeInUp card-width-banner"
                                            style={{ marginBottom: 0, paddingTop: 10 }}
                                        >
                                            <div
                                                className="flicking-viewport"
                                                style={{
                                                    userSelect: "none",
                                                    WebkitUserDrag: "none",
                                                    touchAction: "pan-y"
                                                }}
                                            >
                                                <div
                                                    className="flicking-camera"
                                                    style={{ transform: "translate(-2.35617px)" }}
                                                >
                                                    <div className="card-panel">
                                                        <img
                                                            className="px-1 img-banner"
                                                            src="/build/web/igame-index-lobby-wm/img/2567-08-14 00.44.55.jpg"
                                                            alt="First slide"
                                                            style={{ borderRadius: "0.5rem !important" }}
                                                        />
                                                    </div>
                                                    <div className="card-panel">
                                                        <img
                                                            className="px-1 img-banner"
                                                            src="/build/web/igame-index-lobby-wm/img/2567-08-14 00.44.55.jpg"
                                                            alt="First slide"
                                                            style={{ borderRadius: "0.5rem !important" }}
                                                        />
                                                    </div>
                                                    <div className="card-panel">
                                                        <img
                                                            className="px-1 img-banner"
                                                            src="/build/web/igame-index-lobby-wm/img/2567-08-14 00.44.55.jpg"
                                                            alt="First slide"
                                                            style={{ borderRadius: "0.5rem !important" }}
                                                        />
                                                    </div>
                                                    <div className="card-panel">
                                                        <img
                                                            className="px-1 img-banner"
                                                            src="/build/web/igame-index-lobby-wm/img/2567-08-14 00.44.55.jpg"
                                                            alt="First slide"
                                                            style={{ borderRadius: "0.5rem !important" }}
                                                        />
                                                    </div>
                                                    <div className="card-panel">
                                                        <img
                                                            className="px-1 img-banner"
                                                            src="/build/web/igame-index-lobby-wm/img/2567-08-14 00.44.55.jpg"
                                                            alt="First slide"
                                                            style={{ borderRadius: "0.5rem !important" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    data-v-ab1d19ba=""
                                    className="auction animated animatedFadeInUp fadeInUp"
                                    role="button"
                                >
                                    <div className="bg-profile-box-overview p-2 mt-2">
                                        <div className="d-flex justify-content-between row m-0 align-items-center">
                                            <div className="col-12 col-md-12 p-0">
                                                <div className="d-flex justify-content-between elementor-element-play-overview text-center bg-profile-credit-bl p-2">
                                                    <div className="align-self-center">
                                                        <div className="p-1 text-left">
                                                            <div>
                                                                <h3 className="m-0">
                                                                    <b className="p-1">0.00</b>
                                                                </h3>
                                                            </div>
                                                            <small>คืนยอดเสียสะสมทั้งหมด</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-profile-box-overview p-2 mt-2">
                                        <div className="d-flex justify-content-between row m-0 align-items-center">
                                            <div className="col-12 col-md-12 p-0">
                                                <div className="d-flex justify-content-between elementor-element-play-overview text-center bg-profile-credit-bl p-2">
                                                    <div className="align-self-center">
                                                        <div className="p-1 text-left">
                                                            <div>
                                                                <h3 className="m-0">
                                                                    <b className="p-1">0.00</b>
                                                                </h3>
                                                            </div>
                                                            <small>คืนยอดเสียสะสมเดือนนี้</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-profile-box-overview p-2 mt-2">
                                        <div className="bg-color-financial-my-bank bg-profile-box-overview">
                                            <div className="d-flex justify-content-between pt-3 pb-3">
                                                <div>
                                                    <small>คืนยอดเสีย {(cashoutSet != null) ? cashoutSet.percent : 0}%</small>
                                                </div>
                                                <div>
                                                    <small>ยอดเริ่มต้น 0 บาท</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between financial-button mt-2 animated animatedFadeInUp fadeInUp">
                                        <div
                                            role="button"
                                            className="align-self-center p-1 credit-bl-text"
                                        >
                                            <div role="button" className="financial-button-r">
                                                <div className="d-flex justify-content-start">
                                                    <div className="align-self-center">
                                                        <img
                                                            className="logo-profile-user-w p-1"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/48a22d9a-8abe-477f-0f40-57204ce17700/public"
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="align-self-center">
                                                        <div className="username-profile">
                                                            <small>ยอดเสียของคุณ</small>
                                                        </div>
                                                        <div className="username-profile">
                                                            <small>
                                                                <b className="font-aff-wid">{(tblprofile != null) ? tblprofile.Cashtotal : 0}</b>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="align-self-center">
                                            <a role="button"
                                                onClick={(e) => {
                                                    this.opencliamcashout(e, 'bonusrankaccount')
                                                }}>
                                                <div className="bg-text-highlight pd-bl-aff">
                                                    <div
                                                        disabled="false"
                                                        role="button"
                                                        className="btn -submit js-submit-accept-term mt-0 w-100 p-2"
                                                    >
                                                        <img
                                                            className="wid-button p-1"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/de26db72-10a7-4369-5e7e-dd07d4568300/public"
                                                            alt=""
                                                        />
                                                        <span>ถอนเงิน</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    {/**/}
                                </div>
                                <div className="p-2 text-left mt-4 mb-1 animated animatedFadeInUp fadeInUp credit-bl-text">
                                    ประวัติการทำรายการ
                                </div>
                                <div className="animated animatedFadeInUp fadeInUp">
                                    <div>
                                        <div className="text-white-v1 text-center">
                                            <div>
                                                <div className="bg-profile-box-overview p-2 text-center animated animatedFadeInUp fadeInUp">
                                                    <b>
                                                        <img
                                                            className="load-history"
                                                            src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e4ac87bf-3687-4f37-c96e-6ad9e797bf00/public"
                                                            alt=""
                                                        />
                                                        <div className="not-history font-history-menu-text p-2">
                                                            <small>
                                                                <b>ไม่พบรายการ...</b>
                                                            </small>
                                                        </div>
                                                    </b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}
export default App;
