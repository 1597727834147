import React, { Component } from 'react'; //different
//import './style.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 7
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 7
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 3
    }
};
class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            slotlist: [],
            recentlist: [],
            rewardlist: [],
            activePage: 1,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            recentmenu: false,
            playerid: null,
            username: null,
            pathname: "",
            user_id: null,
            point: null,
            userid: null,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            alert: null,
        }

    }


    render() {
        const pathname = this.state.pathname
        return (
            <>
                <div className="box-class">
                    <div>
                        <div className="bg-view-box-section pt-1 pb-1">
                            <div className="container p-0">

                                <div className="carousel__viewport">
                            
                                        <Carousel
                                        arrows={false}
                                            swipeable={false}
                                            draggable={false}
                                            showDots={true}
                                            responsive={responsive}
                                            ssr={true} // means to render carousel on server-side.
                                            infinite={true}
                                            autoPlay={this.props.deviceType !== "mobile" ? true : false}
                                            autoPlaySpeed={1000}
                                            keyBoardControl={true}
                                            customTransition="all .5"
                                            transitionDuration={500}
                                            containerClass="carousel-container"
                                            removeArrowOnDeviceType={["tablet", "mobile"]}
                                            deviceType={this.props.deviceType}
                                            dotListClass="test"
                                            itemClass="carousel-item-padding-40-px"
                                        >
                                            <div
                                                className="carousel__slide carousel__slide--next"
                                                style={{ width: "80%", order: 7 }}
                                            >
                                                <img
                                                    className="w-100 px-2"
                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/07bcba7a-7a0c-4d1e-15cb-26fb38a3e600/public"
                                                    alt="First slide"
                                                    style={{ borderRadius: "0.5rem !important",height:"80%" }}
                                                />
                                            </div>
                                            <div
                                                className="carousel__slide"
                                                style={{ width: "80%", order: 8 }}
                                            >
                                                <img
                                                    className="w-100 px-2"
                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/64537eec-be94-4be2-47a6-756dc1c5c000/public"
                                                    alt="First slide"
                                                    style={{ borderRadius: "0.5rem !important" }}
                                                />
                                            </div>
                                            <div
                                                className="carousel__slide"
                                                style={{ width: "80%", order: 9 }}
                                            >
                                                <img
                                                    className="w-100 px-2"
                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/6a7ead18-07b8-4e71-842b-dfcc221a5d00/public"
                                                    alt="First slide"
                                                    style={{ borderRadius: "0.5rem !important" }}
                                                />
                                            </div>
                                            <div
                                                className="carousel__slide"
                                                style={{ width: "80%", order: 10 }}
                                            >
                                                <img
                                                    className="w-100 px-2"
                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/20572201-044e-490c-e230-32e3374c8a00/public"
                                                    alt="First slide"
                                                    style={{ borderRadius: "0.5rem !important" }}
                                                />
                                            </div>
                                            <div
                                                className="carousel__slide"
                                                style={{ width: "80%", order: 11 }}
                                            >
                                                <img
                                                    className="w-100 px-2"
                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/044ed0b6-fb92-43ce-eb8e-4f5e46f7ca00/public"
                                                    alt="First slide"
                                                    style={{ borderRadius: "0.5rem !important" }}
                                                />
                                            </div>
                                            <div
                                                className="carousel__slide carousel__slide--visible"
                                                style={{ width: "80%", order: 0 }}
                                            >
                                                <img
                                                    className="w-100 px-2"
                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/54c179cd-b256-403b-7cdc-9734704b3900/public"
                                                    alt="First slide"
                                                    style={{ borderRadius: "0.5rem !important" }}
                                                />
                                            </div>
                                            <div
                                                className="carousel__slide carousel__slide--visible"
                                                style={{ width: "80%", order: 1 }}
                                            >
                                                <img
                                                    className="w-100 px-2"
                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/b73f287f-d002-4630-4bfd-aa4cceb27000/public"
                                                    alt="First slide"
                                                    style={{ borderRadius: "0.5rem !important" }}
                                                />
                                            </div>
                                            <div
                                                className="carousel__slide carousel__slide--visible"
                                                style={{ width: "80%", order: 2 }}
                                            >
                                                <img
                                                    className="w-100 px-2"
                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/af06505d-1d58-4d3b-530f-114355fa0e00/public"
                                                    alt="First slide"
                                                    style={{ borderRadius: "0.5rem !important" }}
                                                />
                                            </div>
                                            <div
                                                className="carousel__slide carousel__slide--active carousel__slide--visible"
                                                style={{ width: "80%", order: 3 }}
                                            >
                                                <img
                                                    className="w-100 px-2"
                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/2019b9ac-6a16-4469-2843-5216145cbe00/public"
                                                    alt="First slide"
                                                    style={{ borderRadius: "0.5rem !important" }}
                                                />
                                            </div>
                                            <div
                                                className="carousel__slide carousel__slide--visible"
                                                style={{ width: "80%", order: 4 }}
                                            >
                                                <img
                                                    className="w-100 px-2"
                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/2e851070-5ef3-4dd9-1da3-d78dd483dd00/public"
                                                    alt="First slide"
                                                    style={{ borderRadius: "0.5rem !important" }}
                                                />
                                            </div>
                                            <div
                                                className="carousel__slide carousel__slide--visible"
                                                style={{ width: "80%", order: 5 }}
                                            >
                                                <img
                                                    className="w-100 px-2"
                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/826d3993-4a9d-4b34-0db0-d8418d10b000/public"
                                                    alt="First slide"
                                                    style={{ borderRadius: "0.5rem !important" }}
                                                />
                                            </div>
                                            <div
                                                className="carousel__slide carousel__slide--visible"
                                                style={{ width: "80%", order: 6 }}
                                            >
                                                <img
                                                    className="w-100 px-2"
                                                    src="https://imagedelivery.net/g1yWpHq5ZqQxQIXvcCDaOA/e5ca4a47-0528-45c3-7f13-e181ee13a300/public"
                                                    alt="First slide"
                                                    style={{ borderRadius: "0.5rem !important" }}
                                                />
                                            </div>
                                        </Carousel>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="bg-view-footer pt-3 pb-5-mobile" >
                            <div className="container p-0 text-center text-details-box">
                                <h2 className="-heading-sub-title m-0 p-2-view-page">
                                    <b>Nuanear888 เว็บคาสิโนออนไลน์</b> <br />
                                </h2>
                                <small>
                                    Nuanear888 บาคาร่า คาสิโนออนไลน์ ที่ดีที่สุด
                                    เพื่อประสบการณ์ที่ดีของผู้เล่นอย่างแท้จริง แบบ Nuanear888
                                </small>
                                <div>
                                    <small>
                                        <b>Created website by</b>
                                    </small>
                                </div>
                                <div>
                                    <small>
                                        <b>Terms and Conditions</b>
                                    </small>
                                </div>
                                <div className="pt-2 pb-2">
                                    <small>
                                        <b>Copyright © 2021 Nuanear888 All Rights Reserved.</b>
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Main;
